import gsap from "gsap";
import SplitText from "gsap/SplitText";
gsap.registerPlugin(SplitText);

document.addEventListener("DOMContentLoaded", function () {
  let tl = gsap.timeline();
  let title = document.querySelector(".title");
  let titleSplit = new SplitText(title, { type: "lines" });
  let title2 = document.querySelector(".title h2");
  let subtitle = document.querySelector(".subtitle1");
  let oldLink = document.querySelector(".link");
  let subtitleSplit = new SplitText(subtitle, { type: "words" });

  // after we have done the splitting lets fade on the fullscreen element
  gsap.set(".fullscreen", { autoAlpha: 1 });

  tl.from(titleSplit.lines, {
    duration: 0.75,
    clipPath: "inset(100% 0 0 0)",
    autoAlpha: 0,
    ease: "power3.inOut",
    stagger: 0.3,
    delay: 0.5,
  })
    // .from(title2, {
    //   clipPath: "inset(100% 0 0 0)",
    //   autoAlpha: 0,
    //   ease: "power3.out",
    //   stagger: 1.5,
    // })
    .from(subtitleSplit.words, {
      duration: 0.5,
      opacity: 0,
      y: 10,
      autoAlpha: 0,
      ease: "power3.out",
      stagger: 0.08,
    })
    .from(oldLink, {
      duration: 0.75,
      opacity: 0,
      autoAlpha: 0,
      ease: "power3.out",
      stagger: 1.5,
    });
});
